import React from 'react'
import BeinCrypto from '../../img/marquee/beincrypto.svg'
import CoinDesk from '../../img/marquee/coindesk.png'
import CoinMaket from '../../img/marquee/coinmaket.svg'
import CoinTelegraph from '../../img/marquee/cointelegraph.svg'
import CryptoDaily from '../../img/marquee/cryptodaily.png'
import CryptoNews from '../../img/marquee/cryptonews.png'

export default function AsSeen() {
  return (
    <>
    <div className='as-seen-in pb-5'>
        <div className='container'>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5' data-aos="fade-up">
              <h2 className='title-text-two'>AS <span className='text-yellow'> SEEN </span> IN </h2>
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-5'>
            <div className='marquee-box' data-aos="fade-up">
              <div className='marquee-content scroll'>
                  <img src={BeinCrypto} className="marquee-image desk" />
                  <img src={CoinDesk} className="marquee-image desk" />
                  <img src={CoinMaket} className="marquee-image desk" />
                  <img src={CoinTelegraph} className="marquee-image desk" />
                  <img src={CryptoDaily} className="marquee-image desk" />
                  <img src={CryptoNews} className="marquee-image desk" />
                  <img src={BeinCrypto} className="marquee-image desk" />
                  <img src={CoinDesk} className="marquee-image desk" />
                  <img src={CoinMaket} className="marquee-image desk" />
                  <img src={CoinTelegraph} className="marquee-image desk" />
                  <img src={CryptoDaily} className="marquee-image desk" />
                  <img src={CryptoNews} className="marquee-image desk" />
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

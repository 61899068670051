import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import Countdown from "../../widgets/Countdown";
import StateUsdt from "../../widgets/StateUsdt";
import { getStage } from "../../services/stage";
import SideBar from "../../widgets/SideBar";
import DashboardHeader from "../../widgets/DashboardHeader";
import { getUserDetail } from "../../services/Login";
import { referralLink } from "../../constant/BaseUrl";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { activeType } from "../../constant/enum";
import { getUserDashboard } from "../../services/dashboard";
import WithdrawModal from "../../partial/WithdrawModal";

function Dashboard() {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const [currentStage, setCurrentStage] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [userDashboard, setUserDashboard] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [availableBalance, setAvailableBalance] = useState(0)

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    getStageFun();
  }, [refresh]);

  useEffect(() => {
    if (authenticated) {
      getUserDetailFun();
      getDashboard()
    }
  }, [authenticated, refresh]);

  const getUserDetailFun = async () => {
    const result = await getUserDetail();
    if (result.status) {
      setUserDetail(result.data[0]);
    }
  };

  const getDashboard=async()=>{
    const result=await getUserDashboard()
    if(result.status){
      setUserDashboard(result.data[0])
    }
  }

  const getStageFun = async () => {
    const result = await getStage();
    if (result.status) {
      setCurrentStage(result.data[0]);
    }
  };

  const copyToClipboard = () => {
    if (userDetail?.register_id) {
      toast.dismiss();
      toast.success("Referral link copied successfully");
      copy(referralLink + userDetail?.register_id);
    }
  };
  
  const handleShowModal=(value)=>{
    setShowModal(true)
    setAvailableBalance(value)
  }

  return (
    <>
      <div className="dashboard-view">
        <div className="dashboard-view-scroll">
          <SideBar />
          <div className="dashboard-view-right">
            <DashboardHeader title={"Dashboard"} />

            <div className="dash-r-mid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="black-box box-design">
                    <h2>
                      Total Income : <b>${parseFloat(userDashboard?.total_income).toFixed(4)}</b>
                    </h2>
                    <p>
                      User ID : <b>{userDetail?.register_id}</b>
                    </p>
                    <p>
                      Sponser ID : <b>{userDetail?.sponser_register_id}</b>
                    </p>
                    
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="black-box box-design">
                    <h2>Referral Link</h2>
                    <p className="referral">
                      {userDetail?.is_activated === activeType.Active ? (
                        <>
                          {referralLink + userDetail?.register_id}
                          <button
                            className="btn btn_primary ms-2"
                            onClick={copyToClipboard}
                          >
                            Copy
                          </button>
                        </>
                      ) : (
                        "Referral link will activate after investment"
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="w-100">
                    <Countdown currentStage={currentStage} usdtRaised={userDashboard?.total_usdt_raised}/>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-4">
                  <div className="w-100">
                    <StateUsdt
                      currentStage={currentStage}
                      setRefresh={setRefresh}
                      refresh={refresh}
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="black-box box-design n-mem">
                    <h2>
                      Earned Income : <span> $ {userDashboard?.total_income} </span>
                    </h2>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul>
                        <li>
                          <svg
                            className="ac-mem"
                            fill="#000000"
                            width="800px"
                            height="800px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title></title>
                            <g data-name="Layer 15" id="Layer_15">
                              <path d="M25.12,6H6.88A3.89,3.89,0,0,0,3,9.89V21.11A3.89,3.89,0,0,0,6.88,25H25.12A3.89,3.89,0,0,0,29,21.11V9.89A3.89,3.89,0,0,0,25.12,6ZM6.88,8H25.12A1.89,1.89,0,0,1,27,9.89V11H5V9.89A1.89,1.89,0,0,1,6.88,8ZM25.12,23H6.88A1.89,1.89,0,0,1,5,21.11V13H27v8.11A1.89,1.89,0,0,1,25.12,23Z"></path>
                              <path d="M24,19H19a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z"></path>
                            </g>
                          </svg>
                          Direct Ref Income
                          <b>$ {userDashboard?.total_direct_income}</b>
                        </li>
                        <li>
                          <svg
                            className="ac-mem3"
                            width="800px"
                            height="800px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 11.1667C15 11.1667 14.6941 11.1667 14.625 11.1667C13.6006 11.1667 12.7077 10.7525 12 10.0001C11.2923 10.7525 10.3995 11.1667 9.37504 11.1667C9.30596 11.1667 9.00004 11.1667 9.00004 11.1667C9.00004 11.1667 9 11.9445 9 12.398C9 14.6121 10.2748 16.4726 12 17.0001C13.7252 16.4726 15 14.6121 15 12.398C15 11.9445 15 11.1667 15 11.1667Z"
                              stroke="#000000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M3 12.1302V14.6001C3 16.8403 3 17.9604 3.43597 18.816C3.81947 19.5687 4.43139 20.1806 5.18404 20.5641C6.03969 21.0001 7.15979 21.0001 9.4 21.0001H14.6C16.8402 21.0001 17.9603 21.0001 18.816 20.5641C19.5686 20.1806 20.1805 19.5687 20.564 18.816C21 17.9604 21 16.8403 21 14.6001V12.1302C21 10.9815 21 10.4071 20.8519 9.87819C20.7208 9.40966 20.5053 8.96898 20.2159 8.5778C19.8893 8.1362 19.436 7.78358 18.5292 7.07834L15.9292 5.05612C14.5239 3.96311 13.8213 3.4166 13.0454 3.20653C12.3608 3.02116 11.6392 3.02116 10.9546 3.20653C10.1787 3.4166 9.47608 3.96311 8.07078 5.05612L5.47078 7.07834C4.56404 7.78358 4.11067 8.1362 3.78405 8.5778C3.49473 8.96898 3.2792 9.40966 3.14805 9.87819C3 10.4071 3 10.9815 3 12.1302Z"
                              stroke="#000000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          Level Income
                          <b>$ {userDashboard?.total_level_income}</b>
                        </li>
                        {/* <li>
                          <svg
                            className="ac-mem3"
                            width="800px"
                            height="800px"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 11.1667C15 11.1667 14.6941 11.1667 14.625 11.1667C13.6006 11.1667 12.7077 10.7525 12 10.0001C11.2923 10.7525 10.3995 11.1667 9.37504 11.1667C9.30596 11.1667 9.00004 11.1667 9.00004 11.1667C9.00004 11.1667 9 11.9445 9 12.398C9 14.6121 10.2748 16.4726 12 17.0001C13.7252 16.4726 15 14.6121 15 12.398C15 11.9445 15 11.1667 15 11.1667Z"
                              stroke="#000000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M3 12.1302V14.6001C3 16.8403 3 17.9604 3.43597 18.816C3.81947 19.5687 4.43139 20.1806 5.18404 20.5641C6.03969 21.0001 7.15979 21.0001 9.4 21.0001H14.6C16.8402 21.0001 17.9603 21.0001 18.816 20.5641C19.5686 20.1806 20.1805 19.5687 20.564 18.816C21 17.9604 21 16.8403 21 14.6001V12.1302C21 10.9815 21 10.4071 20.8519 9.87819C20.7208 9.40966 20.5053 8.96898 20.2159 8.5778C19.8893 8.1362 19.436 7.78358 18.5292 7.07834L15.9292 5.05612C14.5239 3.96311 13.8213 3.4166 13.0454 3.20653C12.3608 3.02116 11.6392 3.02116 10.9546 3.20653C10.1787 3.4166 9.47608 3.96311 8.07078 5.05612L5.47078 7.07834C4.56404 7.78358 4.11067 8.1362 3.78405 8.5778C3.49473 8.96898 3.2792 9.40966 3.14805 9.87819C3 10.4071 3 10.9815 3 12.1302Z"
                              stroke="#000000"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>
                          Airdrop
                          <b>$ {userDashboard?.total_airdrop}</b>
                        </li> */}
                        <li>
                          <svg
                            className="ac-mem"
                            fill="#000000"
                            width="800px"
                            height="800px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title></title>
                            <g data-name="Layer 15" id="Layer_15">
                              <path d="M25.12,6H6.88A3.89,3.89,0,0,0,3,9.89V21.11A3.89,3.89,0,0,0,6.88,25H25.12A3.89,3.89,0,0,0,29,21.11V9.89A3.89,3.89,0,0,0,25.12,6ZM6.88,8H25.12A1.89,1.89,0,0,1,27,9.89V11H5V9.89A1.89,1.89,0,0,1,6.88,8ZM25.12,23H6.88A1.89,1.89,0,0,1,5,21.11V13H27v8.11A1.89,1.89,0,0,1,25.12,23Z"></path>
                              <path d="M24,19H19a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z"></path>
                            </g>
                          </svg>
                          Roi Income
                          <b>$ {userDashboard?.total_roi}</b>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div className="black-box box-design n-mem">
                    <h2>Withdrawal</h2>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul>
                        <li>
                          <svg
                            className="ac-mem"
                            fill="#000000"
                            width="800px"
                            height="800px"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title></title>
                            <g data-name="Layer 15" id="Layer_15">
                              <path d="M25.12,6H6.88A3.89,3.89,0,0,0,3,9.89V21.11A3.89,3.89,0,0,0,6.88,25H25.12A3.89,3.89,0,0,0,29,21.11V9.89A3.89,3.89,0,0,0,25.12,6ZM6.88,8H25.12A1.89,1.89,0,0,1,27,9.89V11H5V9.89A1.89,1.89,0,0,1,6.88,8ZM25.12,23H6.88A1.89,1.89,0,0,1,5,21.11V13H27v8.11A1.89,1.89,0,0,1,25.12,23Z"></path>
                              <path d="M24,19H19a1,1,0,0,0,0,2h5a1,1,0,0,0,0-2Z"></path>
                            </g>
                          </svg>
                          Available Balance
                          <b>$ {userDashboard?.available_bal}</b>
                        </li>
                      </ul>
                      <button type="button" class="btn btn-primary" onClick={()=>{handleShowModal(userDashboard?.available_bal)}}>
                        Withdraw
                      </button>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-4">
                  <div className="black-box box-design">
                    <h2>Team Details</h2>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div class="team-add-box">
                          <h3>Your Team</h3>
                          <p>{userDashboard?.total_team}</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-xs-12">
                        <div class="team-add-box">
                          <h3>Direct Team</h3>
                          <p>{userDashboard?.total_direct_team}</p>
                        </div>
                      </div>

                      <div class="col-lg-3 col-md-3 col-xs-12">
                        <div class="team-add-box">
                          <h3>Total Business</h3>
                          {}
                          <p>USDT {userDashboard?.total_team_business?.total_usdt_amount} </p>
                          <p>DOA {userDashboard?.total_team_business?.total_doa_token}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WithdrawModal availableBalance={availableBalance} setShowModal={setShowModal} showModal={showModal} doa_price={currentStage?.doa_price} refresh={refresh} setRefresh={setRefresh}/>
    </>
  );
}

export default Dashboard;

import React from 'react'
import LogoMain from '../../img/logo.png'
import DollarRotate from '../../img/dollar-icon.png'

export default function Footer() {
  return (
    <>
    <div className='footer-section pt-5 pb-5' id='contact'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='foot-join'>
                  <h3>Join us on social media for exclusive giveaways and stay connected!</h3>
                  {/* <p>“Exciting Releases of 'Bad Players' Avatars Coming Soon: <b className='text-yellow'> Be Ready for the Fun!" </b></p> */}
                  <div className='socials-wrapper'>
                    <a target='_blank' href='https://x.com/Dollar__O'>
                      <svg class="ikonik-gldea x" xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 20 20" app="ikonik"><path class="path-y6nht" fill="#fff" d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z" app="ikonik"></path></svg>
                    </a>
                    <a target='_blank' href='https://www.facebook.com/people/Dollar-O/61568863670965/'>
                    <i className="fa fa-facebook"></i>
                    </a>
                    <a target='_blank' href='https://www.instagram.com/dollaro.official/'>
                    <i className="fa fa-instagram"></i>
                    </a>
                    <a target='_blank' href='https://www.linkedin.com/company/dollaro/'>
                    <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <div className='footer-bot'>
                <div className='email-foot'>
                  <a href='javascript:void(0);'>contact@dollaro.io</a>
                </div>
                <div className='logo-foot'>
                  <img src={LogoMain} />
                  <img src={DollarRotate} id="slidecaption" className="dollar-rotate" />
                </div>
                <div className='anchor-foot'>
                    <a href='javascript:void(0);'>Privacy</a>
                    <a href='javascript:void(0);'>Cookies</a>
                    <a href='javascript:void(0);'>Disclaimer</a>
                </div>
              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <p className='text-foot'>© 2024 Dollaro. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
      
    </>
  )
}

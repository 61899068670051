import React from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../../widgets/Footer'
import Header from '../../widgets/Header'


function HomeAirdrop() {
    const {referral_code}=useParams()
  return (
    <>
       <Header />
       <div className="top-m"></div>
       <div className='container'>
       <div className='row align-items-center'>
           <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-5 pb-5'>
              <div className='air-center'>
                <h4>DOLLARO</h4>
              </div>
           </div>
       </div>
       </div>
       <Footer />
    </>
  )
}

export default HomeAirdrop

import { insertTaskApi, getTaskApi } from "../constant/api";
import * as opsService from "./Ops";

export const addTask = async (data) => {
  const token = localStorage.getItem("token");
  let result = await opsService.postdata(insertTaskApi, data, token);
  return result;
};
export const getTask = async (data) => {
  const token = localStorage.getItem("token");
  let result = await opsService.getData(getTaskApi, token);
  return result;
};

import { getTeamLevelApi, getTeamReportApi } from "../constant/api";
import * as opsService from "./Ops";

export const getTeamLevel = async () => {
    const token =localStorage.getItem("token")
    let result = await opsService.getData(getTeamLevelApi,token);
    return result;
  };

  export const getTeamReport = async (data) => {
    const token =localStorage.getItem("token")
    let result = await opsService.postdata(getTeamReportApi,data,token);
    return result;
  };
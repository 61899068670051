import React, { useState, useEffect } from "react";
import { getTeamReport } from "../services/team";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatatable from "@mkikets/react-datatable";

function TeamReportModal(props) {
  const [report, setReport] = useState([]);

  useEffect(() => {
    if (props.authenticated && props.userIds?.length > 0) {
      getReport();
    } else {
      setReport([]);
    }
  }, [props.authenticated, props.userIds]);

  const getReport = async () => {
    const result = await getTeamReport({ user_ids: props.userIds });
    if (result.status) {
      setReport(result.data);
    } else {
      setReport([]);
    }
  };

  const handleClose = () => {
    props.setShowModal(false);
  };

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "register_id",
      text: "Register Id",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "name",
      text: "Name",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
    {
      key: "total_business",
      text: "total Business",
      className: "mobile_number",
      align: "left",
      sortable: true,
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleClose}
        dialogClassName="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Team Report </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <ReactDatatable
              config={config}
              records={report}
              columns={columns}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TeamReportModal;

import { baseUrl } from "./BaseUrl";

//=============== Auth ====================
export const userRegisterApi=baseUrl+"/user-register"
export const userLoginApi=baseUrl+"/user-login"
export const userDetailApi=baseUrl+"/user-detail"
export const updateProfileImageapi=baseUrl+"/update-profile-image"
export const getAdminReferralApi=baseUrl+"/get-admin-referral"
export const getReferralTreeapi=baseUrl+"/get-referral-tree"

//=============== satge ===================
export const getStateApi=baseUrl+"/get-stage"

//================= Package ================
export const getPackageApi=baseUrl +"/get-package"

//================ Deposit =================
export const addDepositApi=baseUrl+"/deposit"

//================ Withdarw =================
export const addWithdrawApi=baseUrl+"/withdraw"

//================ Dashboard =============
export const getUserDashboardApi=baseUrl+"/get-user-dashboard"

//=============== stake Report ================
export const getStakeReportApi=baseUrl+"/get-stake-report"

//================= Transaction ============
export const getTransactionReportApi=baseUrl+"/get-transaction-report"

//================== team =================
export const getTeamLevelApi=baseUrl+"/get-team-level"
export const getTeamReportApi=baseUrl+"/get-team-report"


//================== airdrop =================
export const insertTaskApi=baseUrl+"/insert-task"
export const getTaskApi=baseUrl+"/get-task"




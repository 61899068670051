import React, { useState, useEffect } from "react";
import OrganizationalChart from "./orgChart";
import { useNavigate } from "react-router-dom";
import { getTreeData } from "../../services/Login";
import DashboardHeader from "../../widgets/DashboardHeader";
import SideBar from "../../widgets/SideBar";
import { useAuth } from "../../../AuthContext";
const styles = {};

function Tree() {
  const navigate = useNavigate();
  const { authenticated, logout } = useAuth();
  const [data, setData] = useState([]);
  useEffect(() => {
    if (authenticated) {
      getData();
    }
  }, [authenticated]);

  const adminlogout = () => {
    localStorage.clear();
    logout();
    navigate("/");
  };

  const getData = async () => {
    const result = await getTreeData();
    if (result.status) {
      setData(result.data);
    }
  };
  console.log(data,"data")
  return (
    <div className="dashboard-view">
      <div className="dashboard-view-scroll">
        <SideBar />
        <div className="dashboard-view-right">
          <DashboardHeader title={"Geneology"} />

          <div className="dash-r-mid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="black-box box-design">
                  <h2>Geneology</h2>

                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="table-design">
                        <OrganizationalChart data={data} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tree;

import React from "react";
import SlideImgR from "../../img/main.png";
import DollarImg from "../../img/doller-img.png";
import ScanImg from "../../img/scan.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";

export default function HomeSlide() {
  const {authenticated}=useAuth()
  return (
    <>
      <div className="home-slide" id="home-slide">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12">
              <div className="home-slideL">
                <h1 className="title-text text-white mb-4" data-aos="fade-up">
                  Step Into The Future with{" "}
                  <span className="text-yellow">Dollaro</span>
                </h1>
                <p className="text-white p-text" data-aos="fade-up">
                Dollaro is the best way of digital asset management. This platform offers innovative staking, re-staking, and efficient liquidity distribution, which can help you maximize returns with complete confidence.
                </p>
                <div className="btn-two" data-aos="fade-up">
                  {authenticated?<Link to="/dashboard" className="btn btn-primary">
                    Dashboard
                  </Link>:<Link to="/login" className="btn btn-primary">
                    Get Started
                  </Link>}
                  <a href="/assest/dollaro_pdf.pdf" className="btn btn-secondary" target="_blank">
                    White Paper
                  </a>
                  <Link to="https://airdrop.dollaro.io/" className="btn btn-primary">Airdrop</Link>
                </div>

                <div className="scan-box">
                  <div className="">
                    <h3>Scan QR Code & See The Contract</h3>
                    <p>0x5a60437eE157ba8236f7A76Dd06E20CFADD11f70</p>
                  </div>
                  <img
                    src={ScanImg}
                    className="scanImg"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-12 col-xs-12">
              <div className="slide-img" data-aos="fade-up">
                <img
                  src={DollarImg}
                  className="dollarImgZoom zoom-in-zoom-out"
                />
                <img src={SlideImgR} className="img-ani" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

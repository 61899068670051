import React, { useState, useEffect } from "react";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { useAuth } from "../../../AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { bnbChainId, bnbRpcUrl, projectId } from "../../constant/enum";
import { toast } from "react-toastify";
import { getAdminReferral, userRegister } from "../../services/Login";
import { RegisterValid } from "../../validation/RegisterValid";

function Register() {
  const { referralCode } = useParams();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();
  const { authenticated, login, setWallet_address } = useAuth();
  const [walletAddress, setWalletAddress] = useState("");
  // const [referral, setReferral] = useState("");
  const [termcheck, settermcheck] = useState(false);
  const [termvalid, settermvalid] = useState("");
  const [adminReferral, setAdminReferral] = useState("")
  const [registerField, setRegisterField] = useState({
    name: "",
    email: "",
    mobileNo: "",
    referral:""
  });
  const [registerFieldErr, setRegisterFieldErr] = useState({
    name: "",
    email: "",
    mobileNo: "",
    referral:""
  });
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (referralCode) {
      setRegisterField({...registerField, ["referral"]: referralCode});
    }
  }, [referralCode]);

  useEffect(() => {
    getadminReferralCode()
  }, [])
  

  const getadminReferralCode=async()=>{
    const result = await getAdminReferral();
    if (result.status) {
      setAdminReferral(result.data[0]?.register_id);
    } else {
      setAdminReferral("");
    }
  }

  useEffect(() => {
    if (authenticated) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (address && isConnected) {
      setWalletAddress(address);
    } else {
      setWalletAddress("");
    }
  }, [address, isConnected]);

  const handleRegisterField = (e) => {
    const { name, value } = e.target;
    setRegisterField({ ...registerField, [name]: value });
    let checkLogin = RegisterValid(name, value);
    setRegisterFieldErr({ ...registerFieldErr, [name]: checkLogin });
  };

  const handleterm = (e) => {
    settermcheck((current) => !current);
    if ((current) => current === false) {
      settermvalid("This field is required");
    }
    settermvalid("");
  };

  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });
  useEffect(() => {
    if (!isConnected && !walletAddress) {
      modal.open();
    }
  }, [isConnected]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    for (let key in registerField) {
      let checkRegister = RegisterValid(key, registerField[key]);
      setRegisterFieldErr({ ...registerFieldErr, [key]: checkRegister });
      if (checkRegister !== "") {
        return false;
      }
    }

    if ( !walletAddress) {
      toast.dismiss();
      toast.error("Required all fields");
      return;
    }
    if (termcheck === false) {
      settermvalid("This field is required");
      return false;
    }
    setDisable(true);
    let registerData = {
      name: registerField.name,
      email: registerField.email,
      mobile_number: registerField.mobileNo,
      wallet_address: walletAddress,
      referral: registerField.referral,
    };
    let result = await userRegister(registerData);
    if (result.status) {
      let token = result.token;
      localStorage.setItem("token", token);
      login();
      toast.success(result.message);
      setWallet_address(address);
      localStorage.setItem("wallet_address", walletAddress);
      setTimeout(function () {
        navigate("/dashboard", { replace: true });
      }, 2000);
      setDisable(false);
      return false;
    } else {
      setDisable(false);
      toast.dismiss();
      toast.error(result.message);
      return;
    }
  };

  const handleWalletConnect = () => {
    modal.open();
  };

  return (
    <>
      <div className="login-grid">
        <div className="login-form">
          <div className="login-form-box">
            <div className="login-card">
              <div className="wallet-register">
                <h3>Register</h3>

                <form>
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      onChange={handleRegisterField}
                      value={registerField.name}
                      name="name"
                      type="text"
                      className="form-control"
                    />
                    <span className="text-danger">{registerFieldErr.name}</span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      onChange={handleRegisterField}
                      value={registerField.email}
                      name="email"
                      type="text"
                      className="form-control"
                    />
                    <span className="text-danger">
                      {registerFieldErr.email}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Mobile Number</label>
                    <input
                      onChange={handleRegisterField}
                      value={registerField.mobileNo}
                      name="mobileNo"
                      type="tel"
                      className="form-control"
                      maxLength={10}
                      onKeyPress={(event) => {
                        // Allow only numbers and one decimal point
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <span className="text-danger">
                      {registerFieldErr.mobileNo}
                    </span>
                  </div>
                  <div className="form-group">
                    <label className="form-label">Wallet Address</label>
                    <input
                      className="form-control"
                      name="walletAddreses"
                      value={walletAddress}
                      disabled
                      readOnly
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">Referral Code</label>
                    <input
                      className="form-control"
                      name="referral"
                      value={registerField.referral}
                      onChange={handleRegisterField}
                    />
                    <span className="text-danger">
                      {registerFieldErr.referral}
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      name="checkbox"
                      className="form-check-input"
                      type="checkbox"
                      value={termcheck}
                      id="checkbox"
                      onChange={handleterm}
                    />
                    <label
                      className="form-check-label ms-1"
                      htmlFor="flexCheckDefault"
                    >
                      <span>I agree to the </span>
                      <a
                        href="/term"
                        style={{
                          color: "#e9488f",
                        }}
                        target="_blanks"
                      >
                        Terms and Condition*
                      </a>
                    </label>
                    <div style={{ color: "red" }} className="valid-msg">
                      {termvalid}
                    </div>
                  </div>
                </form>
                {isConnected ? (
                  <button
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    disabled={disable}
                  >
                    Register
                  </button>
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={handleWalletConnect}
                  >
                    Connect Wallet
                  </button>
                )}
   <p className="d-flex align-items-center mb-3">Don't have referral code <button className="btn btn-primary btn_small ml-auto mb-0" onClick={()=>{
                        setRegisterField({...registerField,referral:adminReferral})
                      }}>Click here</button></p>
                <p>
                  Already have an account{" "}
                  <Link to="/login" className="forgot-password">
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;

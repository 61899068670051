import {
    Emailpattern,
    Mobilepattern,
    Namepattern,
  } from "../pattern/Pattern";
  
  export const RegisterValid = (name, value,id) => {
    let error = "";
    if (name === "email") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Emailpattern.test(value)) {
        error = "Please enter  valid email address";
        return error;
      }
      return error;
    }
    if (name === "name") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Namepattern.test(value)) {
        error = "Please enter  valid name";
        return error;
      }
      return error;
    }
    if (name === "referral") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
    
      return error;
    }
    if (name === "mobileNo") {
      if (value === "") {
        error = "This field is required";
        return error;
      }
      if (!Mobilepattern.test(value)) {
        error = "Please enter  valid mobile number";
        return error;
      }
      return error;
    }
   
  };
  
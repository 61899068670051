// import './App.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Component/pages/auth/Login";
import Home from "./Component/pages/home/Home";
import Register from "./Component/pages/auth/Register";
import Dashboard from "./Component/pages/dashboard/Dashboard";
import StakeReport from "./Component/pages/stake/StakeReport";
import BuyReport from "./Component/pages/transaction/BuyReport";
import AirDropReport from "./Component/pages/transaction/AirDropReport";
import DirectReport from "./Component/pages/transaction/DirectReport";
import WithdrawReport from "./Component/pages/transaction/WithdrawReport";
import RoiReport from "./Component/pages/transaction/RoiReport";
import Profile from "./Component/pages/profile/Profile";
import TeamReport from "./Component/pages/team/TeamReport";
import LevelReport from "./Component/pages/transaction/LevelReport";
import { Airdrop } from "./Component/pages/airdrop/Airdrop";
import HomeAirdrop from "./Component/pages/airdrop/HomeAirdrop";
import Tree from "./Component/pages/tree/Tree";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />}/>
          <Route path="/register/:referralCode" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/stake-report" element={<StakeReport />} />
          <Route path="/buy-report" element={<BuyReport />} />
          <Route path="/airdrop-report" element={<AirDropReport />} />
          <Route path="/directincome-report" element={<DirectReport />} />
          <Route path="/levelincome-report" element={<LevelReport />} />
          <Route path="/withdraw-report" element={<WithdrawReport />} />
          <Route path="/roi-report" element={<RoiReport />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/team-report" element={<TeamReport />} />
          <Route path="/airdrop" element={<Airdrop />} />
          <Route path="/home-airdrop" element={<HomeAirdrop />} />
          <Route path="/tree" element={<Tree />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;

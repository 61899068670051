import React from 'react'
import TokenImg from '../../img/token.png'
import DollarRotate from '../../img/dollar-icon.png'  
import OcImgOne from '../../img/oc-icon-01.png'
import OcImgTwo from '../../img/oc-icon-02.png'
import OcImgThree from '../../img/oc-icon-04.png'

export default function Tokenomics() {
  return (
    <>
      <div className='about-dollar pt-5 pb-5' id='about-token'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-md-5 mb-3'>
              <h3 className='title-text-two mb-1'>Types of <span className='text-yellow'>Income</span> </h3>
            </div>
            <div className='row mb-5'>
              <div class="col-lg-12 col-md-12 mb-md-5">
                <div className='row'>
                <div class="col-lg-6 col-md-6 aos-init aos-animate">
                  <div class="features-single-item type-d-changes">
                      <img src={OcImgOne} class="img aos-init" />
                      <div>
                      <h3>Staking Income</h3>
                      <p>Earn upto 15% per month by staking your coins. It allows you to watch your investment grow effortlessly over time.</p>
                      </div>
                      <ul>
                        <li>Get 0.20% daily (6% monthly) with investment of $100 to $499.</li>
                        <li>Get 0.30% daily (9% monthly) with investment of $500 to $999.</li>
                        <li>Get 0.40% daily (12% monthly) with investment of $1000 to $4999.</li>
                        <li>Get 0.50% daily (15% monthly) with investment of $5000 or above</li>
                      </ul>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 aos-init aos-animate">
                  <div className='row'>
                      <div class="col-lg-12 col-md-12 aos-init aos-animate">
                        <div class="features-single-item type-d-changes">
                            <img src={OcImgTwo} class="img aos-init" />
                            <div>
                            <h3>Direct Income</h3>
                            <p>Receive a bonus of 5% for every new member you refer. This way, you can expand your network and increase your earnings.</p>
                            </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 aos-init aos-animate">
                        <div class="features-single-item type-d-changes">
                            <img src={OcImgThree} class="img aos-init" />
                            <div>
                            <h3>Level Income</h3>
                            <p>With Dollaro, you can earn income across 4 levels. This will allow you to maximize your earning potential.</p>
                            </div>
                        </div>
                      </div>
                  </div>

                </div>
                
                </div>

              </div>
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-3  mb-md-5'>
              <div className='about-dollarL' data-aos="fade-left">
              <h3 className='title-text-two mb-4' data-aos="fade-up">Referral  <span className='text-yellow'>Program</span> </h3>
              <p className='text-white p-text' data-aos="fade-up"> In our referral system, you have the opportunity to earn up to 5% through a structured, four-level program. This system is designed to reward you for expanding our community and sharing our services with others. </p>
              <p className='text-white p-text' data-aos="fade-up"> As you refer individuals to our platform, you will receive a percentage of the earnings generated from their activity, as well as from their referrals and beyond, creating a cascading effect. This incentivizes not only direct referrals but also the engagement of your network, enhancing the potential for substantial earnings.
              </p>
              <p className='text-white p-text' data-aos="fade-up"> We believe this model fosters collaboration and growth, allowing you to benefit as you help others discover the value of our offerings. Join us in this rewarding initiative and take advantage of the benefits our referral system has to offer.</p>
              </div>
            </div>

            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md-3  mb-md-5'>
              <div className='about-dollarL' data-aos="fade-left">
              <h3 className='title-text-two mb-4' data-aos="fade-up">Dollaro <span className='text-yellow'>Airdrop</span> </h3>
              <p className='text-white p-text' data-aos="fade-up">In our airdrop, Dollaro gives you a chance to claim your share of 1 million coins! Each user can receive up to 100 coins by completing just 4 simple tasks. This is your chance to be part of the Dollaro ecosystem from the very beginning and earn tokens that could grow in value. Don't miss out on this opportunity—join now, complete the tasks, and get rewarded with Dollaro coins.</p>
              </div>
            </div>

         
            

            <div className='col-lg-5 col-md-5 col-sm-12 col-xs-12 mt-md-5' data-aos="fade-right">
              <div className='tokenChart'>
                <img src={TokenImg} className="token-img" alt=''/>
                <img src={DollarRotate} id="slidecaption" className="dollar-rotate" alt=''/>
              </div>
            </div>
            <div className='col-lg-7 col-md-7 col-sm-12 col-xs-12 mt-5'>
              <div className='about-dollarL' data-aos="fade-left">
                <h3 className='title-text-two mb-4' data-aos="fade-up">Dollaro <span className='text-yellow'>Tokenomics</span> </h3>
                <ul class="tokenomics_list">
                  <li><span class="t1"></span> 2% Developing</li>
                  <li><span class="t1"></span> 5% Marketing</li>
                  <li><span class="t1"></span> 60% Sale</li>
                  <li><span class="t1"></span> 20% Reserved</li>
                  <li><span class="t1"></span> 10% Airdrop</li>
                  <li><span class="t1"></span> 3% Exchange Listing</li>
                </ul>
              </div>
            </div>

            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5'>
              <div className='about-dollarL'>
                <p className='text-white p-text' data-aos="fade-up">Built on blockchain technology, Dollaro combines advanced technology with a transparent and efficient staking model. With our strategic liquidity distribution and staking rewards, Dollaro tends to create long-term value for investors. Our innovative tokenomics ensure improved growth and the potential for earning maximum returns. So, don’t miss out on your chance to be part of the next big digital asset revolution!</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      
      {/* <div className='dollar-tokenomics pt-5 pb-5' id='tokenomics'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
              <h3 className='title-text-two mb-4' data-aos="fade-up">Dollaro <span className='text-yellow'>Tokenomics</span> </h3>
              <p className='text-white p-text text-center' data-aos="fade-up">Built on the Ethereum blockchain and powered by the most advanced AI technology, Baby Sinclair has the potential to make millionaires in both early stages and long-term investment. Our strategic burn system, which will burn millions of $CLAIR quarterly, aims to increase its value significantly. Don't miss out! Get in early and secure your chance to become the next millionaire.</p>
              <div data-aos="fade-up">
                <img src={TokenImg} className="img-ani token-img" />
              </div>
            </div>


          </div>  
        </div>  
      </div>  */}
    </>
  )
}

import React, { useState,useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { minWithDrawUsdt } from "../constant/enum";
import { addwithdraw } from "../services/withdraw";

function WithdrawModal(props) {
  const [inputAmount, setInputAmount] = useState(0);
  const [inputAmountErr, setInputAmountErr] = useState("");
  const [availableBalance, setAvailableBalance] = useState(0);
  const [disable, setDisable] = useState(false)

  useEffect(() => {
    if (props.availableBalance) {
      setAvailableBalance(props.availableBalance);
    }
  }, [props.availableBalance]);

  const handleClose = () => {
    props.setShowModal(false);
    setInputAmount("")
    setInputAmountErr("")
  };
  
  const handleChange=(e)=>{
    const {value}=e.target
    setInputAmount(value)
    if(value==""){
        setInputAmountErr("This field is required")
    }
    else{
        setInputAmountErr("")
    }
  }
  
  const handleSubmit=async(e)=>{
    e.preventDefault()
    if (inputAmount == "" || inputAmount <= 0) {
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    if(availableBalance<inputAmount){
      toast.dismiss();
      toast.error("Insufficient Balance");
      return;
    }
    if(props?.doa_price*parseFloat(inputAmount)<minWithDrawUsdt){
      toast.dismiss();
      toast.error(`Please enter minimum ${minWithDrawUsdt/props?.doa_price}`);
      return;
    }
    setDisable(true)
    const sendData={
      amount:inputAmount
    }
    let result = await addwithdraw(sendData);
    if (result.status) {
      props.setRefresh(!props.refresh);
      toast.dismiss();
      toast.success(result.message);
      handleClose()
      setDisable(false)
    } else {
      toast.dismiss();
      toast.error(result.message);
      setDisable(false)
    }
  
  }

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={handleClose}
 
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Withdraw </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            <p><b>Available Balance : </b> {availableBalance}</p>
            <form>
              <div>
                <label className="form-label">Withdraw Amount</label>
                <input
                  className="form-control"
                  value={inputAmount}
                  placeholder="Enter Withdraw Amount"
                  type="tel"
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    const inputValue = event.target.value;

                    // Allow only numbers and one decimal point
                    if (!/[0-9]/.test(event.key) && event.key !== ".") {
                      event.preventDefault();
                    }

                    // Prevent entering more than one decimal point
                    if (event.key === "." && inputValue.includes(".")) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <span className="text-danger">{inputAmountErr}</span>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSubmit} disabled={disable}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WithdrawModal;

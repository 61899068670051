
import { createContext, useContext, useState,useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(localStorage.getItem("token")?true:false);
  const [sideBarMenu, setSideBarMenu] = useState(false)
  const [wallet_address, setWallet_address] = useState(localStorage.getItem("wallet_address")?localStorage.getItem("wallet_address"):"")

 useEffect(() => {
  if(localStorage.getItem("token")){
    setAuthenticated(true);
  }
 }, [authenticated])

  const login = () => {
    // Implement your authentication logic here
    setAuthenticated(true);
  };

  const logout = () => {
    // Implement your logout logic here
    setAuthenticated(false);
    setWallet_address("")
    localStorage.clear()
  };

  const handleSideBar=()=>{
    setSideBarMenu(!sideBarMenu)
  }

  return (
    <AuthContext.Provider value={{ authenticated, login, logout,wallet_address,setWallet_address,handleSideBar,sideBarMenu }}>
      {children}
    </AuthContext.Provider>
  );
};
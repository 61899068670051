import { getAdminReferralApi, getReferralTreeapi, updateProfileImageapi, userDetailApi, userLoginApi, userRegisterApi } from "../constant/api";
import * as opsService from "./Ops";


export const userLogin = async (data) => {
  let result = await opsService.postdata(userLoginApi, data);
  return result;
};

export const userRegister = async (data) => {
  let result = await opsService.postdata(userRegisterApi, data);
  return result;
};

export const getUserDetail = async () => {
  const token =localStorage.getItem("token")
  let result = await opsService.getData(userDetailApi,token);
  return result;
};

export const updateProfileImage = async (data) => {
  const token =localStorage.getItem("token")
  let result = await opsService.postdata(updateProfileImageapi,data,token);
  return result;
};

export const getAdminReferral = async () => {
  let result = await opsService.getData(getAdminReferralApi);
  return result;
};

export const getTreeData=async()=>{
  const config = localStorage.getItem("token");
  let result = await opsService.getData(getReferralTreeapi, config);
  return result;
}
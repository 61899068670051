import React, { useEffect } from "react";
import LogoMain from "../../img/logo.png";
import DollarRotate from "../../img/dollar-icon.png";
import Mclose from "../../img/close-m.svg";
import { useAuth } from "../../AuthContext";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { bnbChainId, bnbRpcUrl, projectId } from "../constant/enum";
import { Link } from "react-router-dom";

function SideBar() {
  const { authenticated, wallet_address, logout,sideBarMenu ,handleSideBar} = useAuth();
  const { address, isConnected } = useWeb3ModalAccount();
  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          window.location.href = "/login";
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href = "/login";
        }
      };
      const timeout = setTimeout(checkConnection, 4000);
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address, logout]);

  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const disconnect = () => {
    if (isConnected) {
      modal.open();
    }
  };

  const handleClick=()=>{
    handleSideBar()
  }
  

  return (
    <>
      <div className={`dashboard-view-left ${sideBarMenu?"active":""}`}>
        <a href="javascript:void(0);" className="m-close" onClick={handleSideBar}>
          <img src={Mclose} />
        </a>
        <div className="dash-top">
          <div className="dash-top-logo">
            <img src={LogoMain} alt="" />
            <img
              src={DollarRotate}
              id="slidecaption"
              className="dollar-rotate"
              alt=""
            />
          </div>
        </div>
        <div className="dash-menu">
          <ul>
            <li>
              <Link to="/dashboard" className={` ${window.location.pathname==="/dashboard"?"active":""}`} onClick={handleClick}>
                Dashboard
              </Link>
            </li>
            <li>
              <Link to="/profile" className={` ${window.location.pathname==="/profile"?"active":""}`} onClick={handleClick}>
                Profile
              </Link>
            </li>
            <li>
              <Link to="/stake-report" className={` ${window.location.pathname==="/stake-report"?"active":""}`} onClick={handleClick}>
                Stake Reports
              </Link>
            </li>
            <li>
              <Link to="/roi-report" className={` ${window.location.pathname==="/roi-report"?"active":""}`} onClick={handleClick}>
               Roi Reports
              </Link>
            </li>
            <li>
              <Link to="/buy-report" className={` ${window.location.pathname==="/buy-report"?"active":""}`} onClick={handleClick}>
                Buy Reports
              </Link>
            </li>
            <li>
              <Link to="/directincome-report" className={` ${window.location.pathname==="/directincome-report"?"active":""}`} onClick={handleClick}>
                Direct Income Reports
              </Link>
            </li>
            <li>
              <Link to="/levelincome-report" className={` ${window.location.pathname==="/levelincome-report"?"active":""}`} onClick={handleClick}>
                Level Income Reports
              </Link>
            </li>
            {/* <li>
              <Link to="/airdrop-report" className={` ${window.location.pathname==="/airdrop-report"?"active":""}`} onClick={handleClick}>
                Airdrop Reports
              </Link>
            </li> */}
            <li>
              <Link to="/withdraw-report" className={` ${window.location.pathname==="/withdraw-report"?"active":""}`} onClick={handleClick}>
                Withdraw Reports
              </Link>
            </li>
           
            {/* <li>
              <a href="javascript:void(0);" className="" onClick={handleClick}> 
                Affiliate
              </a>
            </li> */}
            <li>
              <Link to="/team-report" className={` ${window.location.pathname==="/team-report"?"active":""}`} onClick={handleClick}>
                Team Reports
              </Link>
            </li>
            <li>
              <Link to="/tree" className={` ${window.location.pathname==="/tree"?"active":""}`}>
              Genealogy
              </Link>
            </li>
            
            <li>
              <a href="#"  onClick={disconnect}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SideBar;

import React, { useEffect } from "react";
import Header from "../../widgets/Header";
import HomeSlide from "../../widgets/HomeSlide";
import PriceIncrease from "../../widgets/PriceIncrease";

import AOS from "aos";
import "aos/dist/aos.css";

import AsSeen from "../../widgets/AsSeen";
import AboutDollar from "../../widgets/AboutDollar";
import Tokenomics from "../../widgets/Tokenomics";
import RoadMap from "../../widgets/RoadMap";

import Footer from "../../widgets/Footer";

export default function Home() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1200,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="top-m"></div>
      <HomeSlide />
      {/* <PriceIncrease /> */}
      <AsSeen />
      <AboutDollar />
      <Tokenomics />
      <RoadMap />
      <Footer />
    </>
  );
}

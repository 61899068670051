import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import DashboardHeader from "../../widgets/DashboardHeader";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import { getTeamLevel } from "../../services/team";
import TeamReportModal from "../../partial/TeamReportModal";

function TeamReport() {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const [report, setReport] = useState([]);
  const [userIds, setUserIds] = useState([])
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      getReport();
    }
  }, [authenticated]);

  const getReport = async () => {
    const result = await getTeamLevel();
    if (result.status) {
      setReport(result.data);
    }
  };

  const showTeam=(userIds)=>{
    setUserIds(userIds)
    setShowModal(true)
  }

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "level",
      text: "Level",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_user",
      text: "Total user",
      className: "mobile_number",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record?.user_ids?.length}</>;
      },
    },
    {
        key: "total_user",
        text: "Total user",
        className: "mobile_number",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <button className="btn-btn-primary" onClick={()=>{showTeam(record.user_ids)}}>View</button>;
        },
      },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <>
      <div className="dashboard-view">
        <div className="dashboard-view-scroll">
          <SideBar />
          <div className="dashboard-view-right">
            <DashboardHeader title={"Team Report"} />

            <div className="dash-r-mid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="black-box box-design">
                    <h2>Team Report</h2>

                    <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="table-design">
                      <ReactDatatable
                        config={config}
                        records={report}
                        columns={columns}
                      />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TeamReportModal userIds={userIds} setShowModal={setShowModal} showModal={showModal} authenticated={authenticated}/>
    </>
  );
}

export default TeamReport;

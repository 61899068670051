import React, { useState, useEffect, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SideBar from "../../widgets/SideBar";
import DashboardHeader from "../../widgets/DashboardHeader";
import Profile from "../../../img/profile.png";
import { addTask, getTask } from "../../services/airdrop";
import { toast } from "react-toastify";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
export const Airdrop = () => {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [point, setPoint] = useState("");
  const [task_image, setTask_image] = useState([]);
  const [record, setRecord] = useState([]);
  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
    getTaskHandler();
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = (type, point) => {
    setType(type);
    setPoint(point);
    setShow(true);
  };

  const getTaskHandler = async () => {
    const resp = await getTask();
    // const data = JSON.stringify
    if (resp.status) {
      setRecord(resp?.data);
    } else {
      toast.error(resp.message);
    }
  };

  const tastUpload = async () => {
    const wallet_address = localStorage.getItem("wallet_address");
    const formData = new FormData();

    formData.append("type", type);
    formData.append("wallet_address", wallet_address);
    formData.append("point", point);

    task_image.forEach((file, index) => {
      formData.append("task_image", file);
    });
    const resp = await addTask(formData);

    if (resp.status) {
      toast.success(resp.message);
      getTaskHandler();
      handleClose();
    } else {
      toast.error(resp.message);
    }
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);

    const imageUrls = files.map((file) => URL.createObjectURL(file));
    setTask_image(imageUrls);

    setTask_image(files);
  };

  return (
    <>
      <div className="dashboard-view">
        <div className="dashboard-view-scroll">
          <SideBar />
          <div className="dashboard-view-right">
            <DashboardHeader title={"Airdrop"} />
            <div className="airdrop-padding">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="black-box box-design airdrop-box">
                    <h3>Posting image of coin</h3>
                    <div className="airdrop-screen">
                      <div className="img-multipal">
                        {record?.map((item, index) => {
                          const imgarray = JSON.parse(item?.task_image);

                          return (
                            <>
                              {item.type == "imageOfCoin" &&
                                imgarray?.map((imgSrc, imgIndex) => (
                                  <span key={imgIndex}>
                                    <img
                                      src={imgSrc}
                                      alt={`Image ${imgIndex}`}
                                    />
                                  </span>
                                ))}
                            </>
                          );
                        })}
                      </div>

                      <div className="airdrop-screen-one">
                        <p>
                          Point : <span>20</span>
                        </p>

                        {record
                          .filter((item) => item?.type === "imageOfCoin")
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {item?.status === "pending" ? (
                                <span className="btns pending">Pending</span>
                              ) : (
                                <a
                                  href="#"
                                  onClick={() => handleShow("imageOfCoin", 20)}
                                  className="btn btn-secondary"
                                >
                                  Get
                                </a>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-5">
                  <div className="black-box box-design airdrop-box">
                    <h3>Like/Comment/Share</h3>
                    <div className="airdrop-screen">
                      <div className="img-multipal">
                        {record?.map((item, index) => {
                          const imgarray = JSON.parse(item?.task_image);

                          return (
                            <>
                              {item.type == "like" &&
                                imgarray?.map((imgSrc, imgIndex) => (
                                  <span key={imgIndex}>
                                    <img
                                      src={imgSrc}
                                      alt={`Image ${imgIndex}`}
                                    />
                                  </span>
                                ))}
                            </>
                          );
                        })}
                      </div>

                      <div className="airdrop-screen-one">
                        <p>
                          Point : <span>20</span>
                        </p>
                        {/* <span className="btns pending">Pending</span>
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleShow("like", 20)}
                          className="btn btn-secondary"
                        >
                          Get
                        </a> */}
                        {record
                          .filter((item) => item?.type === "like")
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {item?.status === "pending" ? (
                                <span className="btns pending">Pending</span>
                              ) : (
                                <a
                                  href="#"
                                  onClick={() => handleShow("like", 20)}
                                  className="btn btn-secondary"
                                >
                                  Get
                                </a>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-5">
                  <div className="black-box box-design airdrop-box">
                    <h3>Create video of coin and post</h3>
                    <div className="airdrop-screen">
                      <div className="img-multipal">
                        {record?.map((item, index) => {
                          const imgarray = JSON.parse(item?.task_image);

                          return (
                            <>
                              {item.type == "post" &&
                                imgarray?.map((imgSrc, imgIndex) => (
                                  <span key={imgIndex}>
                                    <img
                                      src={imgSrc}
                                      alt={`Image ${imgIndex}`}
                                    />
                                  </span>
                                ))}
                            </>
                          );
                        })}
                      </div>
                      <div className="airdrop-screen-one">
                        <p>
                          Point : <span>20</span>
                        </p>

                        {record.some(
                          (item) =>
                            item?.type === "post" && item?.status === "pending"
                        ) ? (
                          <span className="btns pending">Pending</span>
                        ) : (
                          <a
                            href="#"
                            onClick={() => handleShow("post", 20)}
                            className="btn btn-secondary"
                          >
                            Get
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="black-box box-design airdrop-box">
                    <h3>Task One Demo</h3>
                    <div className="airdrop-screen">
                      <div className="img-multipal">
                        <span>
                          <img src={Profile} />
                        </span>
                        <span>
                          <img src={Profile} />
                        </span>
                        <span>
                          <img src={Profile} />
                        </span>
                        <span></span>
                        <span>
                          <img src={Profile} />
                        </span>
                      </div>
                      <div className="airdrop-screen-one">
                        <p>
                          Point : <span>20</span>
                        </p>
                        <span className="btns pending">Pending</span>
                        <a
                          href="javascript:void(0);"
                          onClick={handleShow}
                          className="btn btn-secondary"
                        >
                          Get
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Screenshort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="uploadscreen">
            <div className="row">
              <div class="col-md-12 mb-2">
                <label class="form-label">Upload Screenshort</label>
                <div className="img-multipal">
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label htmlFor="fileInput" className="upload-button">
                    Select Images
                  </label>

                  {task_image.map((image, index) => (
                    <span key={index}>
                      <img src={image} alt={`Selected ${index}`} />
                    </span>
                  ))}

                  {[...Array(5 - task_image.length)].map((_, index) => (
                    <span key={`empty-${index}`}></span>
                  ))}
                </div>
              </div>
              <div class="col-md-6 mb-2 mt-3">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => tastUpload()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../../widgets/DashboardHeader";
import SideBar from "../../widgets/SideBar";
import profile from "../../../img/profile.png";
import { getUserDetail, updateProfileImage } from "../../services/Login";
import { useAuth } from "../../../AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RegisterValid } from "../../validation/RegisterValid";

function Profile() {
  const navigate = useNavigate();
  const myref = useRef();
  const [userDetail, setUserDetail] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { authenticated } = useAuth();
  const [registerField, setRegisterField] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });
  const [registerFieldErr, setRegisterFieldErr] = useState({
    name: "",
    email: "",
    mobileNo: "",
  });

  useEffect(() => {
    if (!authenticated) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      getUserDetailFun();
    }
  }, [authenticated, refresh]);

  const getUserDetailFun = async () => {
    const result = await getUserDetail();
    if (result.status) {
      setUserDetail(result.data[0]);
      setRegisterField({name:result.data[0].name,email:result.data[0].email,mobileNo:result.data[0].mobile_number})
    }
  };

  const saveFile = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile_image", e.target.files[0]);
    try {
      const result=await updateProfileImage(formData)
      if(result.status){
        toast.dismiss()
        toast.success(result.message)
        setRefresh(!refresh)
      }
      else{
        toast.dismiss()
        toast.error(result.message)
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleRegisterField = (e) => {
    const { name, value } = e.target;
    setRegisterField({ ...registerField, [name]: value });
    let checkLogin = RegisterValid(name, value);
    setRegisterFieldErr({ ...registerFieldErr, [name]: checkLogin });
  };


  return (
    <>
      <div className="dashboard-view">
        <div className="dashboard-view-scroll">
          <SideBar />
          <div className="dashboard-view-right">
            <DashboardHeader title={"Profile"} />

            <div className="dash-r-mid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="black-box box-design">
                    <h2>Profile </h2>

                    <div className="row">
                      <div className="col-md-4 profile-img">
                     <img src= {userDetail?.image?userDetail?.image:profile} alt=""/>
                     <div className="edit-profile">
                      <label htmlFor="img1" className="editlabel">Edit</label>
                      <input
                        type="file"
                        ref={myref}
                        id="img1"
                        style={{ display: "none" }}
                        onChange={saveFile}
                        accept="image/png, image/jpeg, image/jpg"
                      />
                      </div>
                      </div>
                      <div className="col-md-8">
                        <div>
                           <p><b>Wallet Address : </b> {userDetail?.wallet_address}</p>
                        </div>
                        <div>
                          <form>
                            <div className="row">
                               <div className="col-md-6 mb-2">
                                <label className="form-label">Name</label>
                                <input value={registerField.name} name="name" className="form-control" onChange={handleRegisterField}/>
                               </div>
                               <div className="col-md-6 mb-2">
                               <label className="form-label">Email</label>
                                <input value={registerField.email} name="email" className="form-control" onChange={handleRegisterField}/>
                               </div>
                               <div className="col-md-6 mb-2">
                               <label className="form-label">Mobile Number</label>
                                <input value={registerField.mobileNo} name="mobileNo" className="form-control" onChange={handleRegisterField}/>
                               </div>
                               <div className="col-md-6 mb-2">

                               </div>
                               <div className="col-md-6 mb-2">

                               </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { getUserDetail } from "../services/Login";
import MenuM from "../../img/m-menu.svg";


function DashboardHeader({ title }) {
  const { authenticated,handleSideBar } = useAuth();
  const [userDetail, setUserDetail] = useState({});

  useEffect(() => {
    if (authenticated) {
      getUserDetailFun();
    }
  }, [authenticated]);

  const getUserDetailFun = async () => {
    const result = await getUserDetail();
    if (result.status) {
      setUserDetail(result.data[0]);
    }
  };

  return (
    <>
      <div className="dash-r-top">
        <div className="container-fuild">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="dash-flex">
                <h1><img src={MenuM} className="m-menu" onClick={handleSideBar}/> {title}</h1>
                <div className="dash-m-r">
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {userDetail?.name}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link className="dropdown-item" to="/profile">
                          Profile
                        </Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardHeader;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../AuthContext";
import { transType } from "../../constant/enum";
import { getTransactionReport } from "../../services/transaction";
import DashboardHeader from "../../widgets/DashboardHeader";
import SideBar from "../../widgets/SideBar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";

function DirectReport() {
    const navigate = useNavigate();
    const { authenticated } = useAuth();
    const [report, setReport] = useState([]);
    useEffect(() => {
      if (!authenticated) {
        navigate("/login");
      }
    }, []);
    useEffect(() => {
      if (authenticated) {
        getReport();
      }
    }, [authenticated]);
  
    const getReport = async () => {
      const result = await getTransactionReport({ trans_type: transType.DirectIncome });
      if (result.status) {
        setReport(result.data);
      }
    };
  
    const columns = [
      {
        key: "srno.",
        text: "Sr.No.",
        className: "sr_no.",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "referral_id",
        text: "Referral_id",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "level",
        text: "Level",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "doa_token",
        text: "DOA Token",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "trans_hash",
        text: "Trans Hash ",
        className: "mobile_number",
        align: "left",
        sortable: true,
      },
      {
        key: "trans_status",
        text: "Status",
        className: "mobile_number",
        align: "left",
        sortable: true,
      },
      {
        key: "start_date",
        text: "Date",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          const date = record.createdAt;
          return <>{moment(date).format("lll")}</>;
        },
      },
    ];
  
    const config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Fund Request List",
      no_data_text: "No record found!",
      button: {
        // print: true,
        // csv: true,
      },
      language: {
        filter: "Search in records...",
  
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

  return (
    <>
       <div className="dashboard-view">
        <div className="dashboard-view-scroll">
          <SideBar />
          <div className="dashboard-view-right">
            <DashboardHeader title={"Direct Income Report"} />

            <div className="dash-r-mid">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="black-box box-design">
                    <h2>Direct Income Report</h2>

                    <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="table-design">
                      <ReactDatatable
                        config={config}
                        records={report}
                        columns={columns}
                      />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DirectReport

import React, { useEffect } from "react";
import LogoMain from "../../img/logo.png";
import DollarRotate from "../../img/dollar-icon.png";
import { Link } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import {
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import { bnbChainId, bnbRpcUrl, projectId } from "../constant/enum";

export default function Header() {
  const { authenticated, wallet_address, logout } = useAuth();
  const { address, isConnected } = useWeb3ModalAccount();
  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          window.location.href = "/login";
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href = "/login";
        }
      };
      const timeout = setTimeout(checkConnection, 4000);
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address, logout]);

  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Pay Earn",
    description: "Pay Earn",
    url: "https://bitpayearn.com/",
    icons: ["https://bitpayearn.com/assets/images/favicon.png.png"],
  };

  const modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const disconnect = () => {
    if (isConnected) {
      modal.open();
    }
  };
  return (
    <>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src={LogoMain} alt="" />
                <img
                  src={DollarRotate}
                  id="slidecaption"
                  className="dollar-rotate"
                  alt=""
                />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse f-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#home-slide"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#about-token">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#tokenomics">
                      Tokenomics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#roadmap">
                      Roadmap
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#contact">
                      Contact Us
                    </a>
                  </li>
                  {authenticated ? (
                     <li className="nav-item">
                     <button className="nav-link btn btn-primary"   onClick={disconnect}>
                     {`${wallet_address.substring(0, 7)}...${wallet_address.substring(wallet_address.length - 7)}`}
                     </button>
                   </li>
                  ) : (
                    <li className="nav-item">
                      <Link className="nav-link btn btn-primary" to="/login">
                        Login
                      </Link>
                    </li>
                  )}

                  {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li> */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}

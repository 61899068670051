import React, { useEffect, useState } from "react";
import UsdtImg from "../../img/usdt-tab.png";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, parseUnits } from "ethers";
import {
  bnbChainId,
  bnbRpcUrl,
  contractAddress,
  decimal,
  tokenabi,
  tokenAddress,
  projectId,
  networketh,
  chainName,
  explorerUrlBsc,
  nativeCurrency,
  symbol,
  contractAbi,
} from "../constant/enum";
import { toast } from "react-toastify";
import { getPackage } from "../services/package";
import { addDeposit } from "../services/deposit";

export default function StateUsdt(props) {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [currentStage, setCurrentStage] = useState({});
  const [checkAllowance, setCheckAllowance] = useState(true);
  const [inputAmount, setInputAmount] = useState(0);
  const [doaToken, setDoaToken] = useState(0);
  const [minAmountInvest, setMinAmountInvest] = useState(0);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (props.currentStage) {
      setCurrentStage(props.currentStage);
    }
  }, [props.currentStage]);

  useEffect(() => {
    if (inputAmount > 0 && inputAmount) {
      approveToken();
    }
  }, [inputAmount]);

  useEffect(() => {
    getMinAmount();
  }, []);

  const getMinAmount = async () => {
    const result = await getPackage();
    if (result.status) {
      setMinAmountInvest(result.data[0]?.valid_min_amount);
    }
  };

  const mainnet = [
    {
      chainId: Number(bnbChainId),
      name: "Bnb mainnet",
      currency: "BNB",
      explorerUrl: "https://bscscan.com/",
      rpcUrl: bnbRpcUrl,
    },
  ];

  const metadata = {
    name: "Dolloro",
    description: "Dolloro",
    url: "https://Dolloro.com/",
    icons: ["https://Dolloro.com/assets/images/favicon.png.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const getSignerOrProvider = async (needSigner = false) => {
    try {
      if (!isConnected) {
        throw Error("User disconnected");
      }

      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();

      if (needSigner) {
        const signer = await ethersProvider.getSigner();

        return signer;
      }

      return signer;
    } catch (error) {
      throw error;
    }
  };

  const approveToken = async () => {
    try {
      const provider = await getSignerOrProvider();
      const contract = new Contract(tokenAddress, tokenabi, provider);
      const allowance = await contract.allowance(address, contractAddress);
      const requiredAllowance = Number(inputAmount) * Number(10 ** decimal);
      if (Number(allowance) < requiredAllowance) {
        setCheckAllowance(false);
        return false;
      } else {
        setCheckAllowance(true);
        return true;
      }
    } catch (error) {
      setCheckAllowance(false);
      return false;
    }
  };

  const calculateToken = async (value) => {
    console.log(currentStage,"currentStage")
    if (value && value != ".") {
      const getdoaToken = parseFloat(value) / currentStage?.doa_price;
      setDoaToken(parseFloat(getdoaToken).toFixed(2));
    } else {
      setDoaToken(0);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setInputAmount(value);
    approveToken();
    calculateToken(value);
  };
  const switchChain = async (
    chain,
    chainName,
    rpcUrl,
    explorer,
    native,
    symbol
  ) => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chain }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: chain,
                chainName: chainName,
                rpcUrls: [rpcUrl],
                blockExplorerUrls: [explorer],
                nativeCurrency: {
                  name: native,
                  symbol: symbol,
                  decimals: 18,
                },
              },
            ],
          });
        } catch (addError) {
          console.error(addError);
        }
      } else {
        console.error(switchError);
      }
    }
  };

  const switchToChain = async () => {
    await switchChain(
      networketh,
      chainName,
      bnbRpcUrl,
      explorerUrlBsc,
      nativeCurrency,
      symbol
    );

    if (chainId != bnbChainId) {
      toast.error(`Please change network Bsc`);
      setDisable(false);
      return false;
    }
    return true;
  };

  const approvalAmount = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.dismiss();
      toast.error("Please connect wallet address");
      return;
    }
    if (inputAmount == "" || inputAmount <= 0) {
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    if(!/^-?\d+(\.\d+)?$/.test(inputAmount)){
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    if (minAmountInvest > parseFloat(inputAmount)) {
      toast.dismiss();
      toast.error(`Please enter more than ${minAmountInvest}`);
      return false;
    }
    setDisable(true)
    try {
      const result = await switchToChain();
      if (result) {
        const provider = await getSignerOrProvider();
        const contract = new Contract(tokenAddress, tokenabi, provider);
        const approvalTx = await contract.approve(
          contractAddress,
          "10000000000000000000000000000000000000000000000000000"
        );
        await approvalTx.wait();
        toast.dismiss();
        toast.success("Approved successfully. Please don't refresh the page.");
        setDisable(false);
        setCheckAllowance(true);
        return false;
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Approved failed.");
      setDisable(false);
    }
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.dismiss();
      toast.error("Please connect wallet address");
      return;
    }
    if (inputAmount == "" || inputAmount <= 0) {
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    if(!/^-?\d+(\.\d+)?$/.test(inputAmount)){
      toast.dismiss();
      toast.error("Please enter valid amount");
      return;
    }
    if (minAmountInvest > parseFloat(inputAmount)) {
      toast.dismiss();
      toast.error(`Please enter more than ${minAmountInvest}`);
      return false;
    }
    setDisable(true);
    if (
      new Date(currentStage.end_date) > new Date() &&
      currentStage.left_token_for_sale >= doaToken
    ) {
      try {
        const checkA = await approveToken();
        if (checkA) {
          const provider = await getSignerOrProvider();
          const contract = new Contract(contractAddress, contractAbi, provider);
          let buyAmount = parseUnits(inputAmount.toString());
          let tx = await contract.deposit(buyAmount.toString());
          toast.dismiss();
          toast.success("Your package buy successfully please wait a second");
          await tx.wait();
          if (tx) {
            const sendData = {
              amount: inputAmount,
              doa_token: doaToken,
              trans_hash: tx.hash,
            };
            let result = await addDeposit(sendData);
            if (result.status) {
              props.setRefresh(!props.refresh);
              toast.dismiss();
              toast.success(result.message);
            } else {
              toast.dismiss();
              toast.error(result.message);
            }
          }
          setTimeout(() => {
            setDisable(false);
            setInputAmount(0);
            setDoaToken(0)
          }, 10000);
        } else {
          toast.dismiss();
          toast.error(`Please approve the amount`);
          setDisable(false);
          return;
        }
      } catch (error) {
        toast.dismiss();
        toast.error(error.reason);
        setDisable(false);
      }
    } else {
      const todayDate = new Date(); // Today's date and time
      const targetDate = new Date(currentStage.end_date); // Target date
      const timeDiff = targetDate - todayDate;
      const remainingDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      toast.dismiss();
      toast.error(`Please invest after ${remainingDays} days`);
      setDisable(false);
      return;
    }
  };

  return (
    <>
      <div className="state-usdt">
        <div className="state-usdtT">
          <h3>
            <img src={UsdtImg} /> usdt
          </h3>
          <div className="state-usdt-text">
            <p>
              1 DOA ={" "}
              <span className="text-yellow"> ${currentStage?.doa_price} </span>
            </p>
            <p>
              Listing Price = <span className="text-yellow"> $5 </span>
            </p>
          </div>
          <div className="state-usdt-input">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-input">
                  <label>USDT You Pay</label>
                  <input
                    type="tel"
                    placeholder="Enter USDT"
                    name="inputAmount"
                    onChange={handleChange}
                    value={inputAmount}
                    onKeyPress={(event) => {
                      const inputValue = event.target.value;

                      // Allow only numbers and one decimal point
                      if (!/[0-9]/.test(event.key) && event.key !== ".") {
                        event.preventDefault();
                      }

                      // Prevent entering more than one decimal point
                      if (event.key === "." && inputValue.includes(".")) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-input">
                  <label>DOA You Receive</label>
                  <input
                    disabled
                    value={doaToken}
                    type="tel"
                    placeholder="Enter Token"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-input">
                  <div className="form-input-btn d-md-flex align-tms-center">
                    <div className="change-btn">
                      <w3m-button balance="hide" />
                    </div>
                    {checkAllowance ? (
                      <button
                        onClick={handleDeposit}
                        type="button"
                        className="btn btn-primary"
                        disabled={disable}
                      >
                        BUY
                      </button>
                    ) : (
                      <button
                        onClick={approvalAmount}
                        type="button"
                        className="btn btn-primary mt-3 mt-md-0"
                        disabled={disable}
                      >
                        Approve
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

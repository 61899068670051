import React, { useState, useEffect } from "react";

export default function Countdown(props) {
  const [stage] = useState([1, 2, 3, 4]);
  const [currentStage, setCurrentStage] = useState({})
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if(props.currentStage){
      setCurrentStage(props.currentStage)
    }
  }, [props.currentStage])
  

  // Set your target ICO end date here
  const icoEndDate = new Date(currentStage.end_date).getTime();

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      const now = new Date().getTime();
      const distance = icoEndDate - now;

      if (distance < 0) {
        clearInterval(countdownInterval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        setTimeLeft({
          days: Math.floor(distance / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((distance % (1000 * 60)) / 1000),
        });
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [icoEndDate]);

  return (
    <>
      <div className="countdown">
        <div className="home-slideR">
          <h2>Stage {currentStage?.stage_name} Ends In :</h2>
          <div className="countdown">
            <ul>
              <li>
                <span>{String(timeLeft.days).padStart(2, "0")}</span>
                <b>Days</b>
              </li>
              <li>
                <span>{String(timeLeft.hours).padStart(2, "0")}</span>
                <b>Hours</b>
              </li>
              <li>
                <span>{String(timeLeft.minutes).padStart(2, "0")}</span>
                <b>Minutes</b>
              </li>
              <li>
                <span>{String(timeLeft.seconds).padStart(2, "0")}</span>
                <b>Seconds</b>
              </li>
            </ul>
          </div>

          <h3>
            USDT Raised <span className="text-yellow"> ${props?.usdtRaised}</span>
          </h3>

          <div className="pro_man mt-2 d-flex">
            {stage?.map((list) => {
          
              return (
                <div key={list}>
                  <label>Stage {list}</label>
                  <div className={`progress ${parseFloat(currentStage?.stage_name)>list?"active":""}`}>
                    <div className="progress-bar"></div>
                  </div>
                  <label></label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
